import React from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  createColumnHelper,
  getFilteredRowModel,
} from '@tanstack/react-table';

import type { AppealReviewSummary } from '../../api';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ClickableRow,
  FilterBar,
  SearchInput,
  Table,
  TableCell,
  TableContainer,
  TableHeader,
} from '../Table';

const columnHelper = createColumnHelper<AppealReviewSummary>();

interface AppealReviewTableProps {
  data: AppealReviewSummary[];
}

const AppealReviewsTable: React.FC<AppealReviewTableProps> = ({ data }) => {
  const navigate = useNavigate();
  const { appealReviewId } = useParams();

  const columns = [
    columnHelper.accessor('patient_name', {
      header: 'Patient',
      enableGlobalFilter: true,
    }),
    columnHelper.accessor('drug_name', {
      header: 'Drug',
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('prescriber_name', {
      header: 'Prescriber',
      enableGlobalFilter: true,
    }),
    columnHelper.accessor('prescription_recieved_at', {
      header: 'Rx Date',
      cell: (info) => new Date(info.getValue()).toLocaleDateString(),
      enableGlobalFilter: false,
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    enableGlobalFilter: true,
    globalFilterFn: 'includesString',
    initialState: { columnVisibility: { appealReview_status: false } },
  });

  return (
    <TableContainer>
      <FilterBar>
        <SearchInput
          label="Search by name"
          hideLabel={true}
          onChange={(e) => table.setGlobalFilter((e.target as HTMLInputElement).value)}
        />
      </FilterBar>
      <Table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHeader key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHeader>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <ClickableRow
                $selected={row.original.id === appealReviewId}
                key={row.original.id}
                onClick={() => navigate(row.original.id)}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                ))}
              </ClickableRow>
            ))
          ) : (
            <tr>
              <TableCell colSpan={columns.length}>No results.</TableCell>
            </tr>
          )}
        </tbody>
      </Table>
    </TableContainer>
  );
};

export default AppealReviewsTable;
