import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Content, Overlay } from '../Slideout';
import { AppealReviewContext } from './contexts';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AppealReviewResponse, client } from '../../api';
import { appealReviewQueryKey, appealReviewsQueryKey } from '../../queries';
import AppealReviewView from './AppealReviewView';

const AppealReviewOverlay: React.FC = () => {
  const params = useParams();
  const appealReviewId = params.appealReviewId!;

  const queryClient = useQueryClient();
  const appealReviewQuery = useQuery<AppealReviewResponse>({
    queryKey: appealReviewQueryKey(appealReviewId),
    queryFn: async () => {
      const res = await client.get(`appeal-reviews/${appealReviewId}`);
      return res.data;
    },
  });

  const [updating, setUpdating] = useState(false);

  return (
    <Overlay>
      <Content>
        <AppealReviewContext.Provider
          value={{
            appealReviewId,
            appealReviewQuery,
            updating,
            setUpdating,
            refetchAppealReview: async () =>
              queryClient.invalidateQueries({
                queryKey: appealReviewQueryKey(appealReviewId),
                exact: true,
              }),
            onAppealReviewUpdate: async () => {
              queryClient.invalidateQueries({
                queryKey: appealReviewsQueryKey(),
              });
              queryClient.invalidateQueries({
                queryKey: appealReviewQueryKey(appealReviewId),
              });
              setUpdating(false);
            },
          }}
        >
          <AppealReviewView />
        </AppealReviewContext.Provider>
      </Content>
    </Overlay>
  );
};

export default AppealReviewOverlay;
