import { useParams } from 'react-router-dom';
import { PriorAuthResponse, client } from '../api';
import { useQuery } from '@tanstack/react-query';
import PageContainer from '../components/PageContainer';
import PriorAuthView from '../components/pas/PriorAuthView';

const PriorAuthPage = () => {
  const { paId } = useParams();
  const { data, isLoading, error } = useQuery<PriorAuthResponse>({
    queryKey: ['prior-auth', paId],
    queryFn: () => {
      return client.get(`prior-auths/${paId}`).then((res) => res.data);
    },
  });

  let body;
  if (isLoading) {
    body = 'Loading prior auth...';
  } else if (error) {
    body = 'Error, please try again';
  } else if (data) {
    body = <PriorAuthView {...data} />;
  }
  return <PageContainer title="Prior Auth">{body}</PageContainer>;
};

export default PriorAuthPage;
