import { client } from './api';
import React, { PropsWithChildren } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Navigate, useLocation } from 'react-router-dom';

type Tabs = 'dashboard' | 'tasks' | 'requests' | 'appeal_reviews';

type User = {
  tabs: Tabs[];
  email: string;
};

export const AuthContext = React.createContext<{
  auth: boolean;
  user: User | null;
  loaded: boolean;
}>({
  auth: false,
  user: null,
  loaded: false,
});

export const LoginProvider = (props: PropsWithChildren<{}>) => {
  const { isLoading, isError, data } = useQuery({
    queryKey: ['checkLogin'],
    queryFn: () => client.get('info').then((res) => res.data),
    retry: false,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  let value;

  if (isError) {
    value = { auth: false, user: null, loaded: true };
  } else {
    value = { auth: true, user: data.user, loaded: true };
  }

  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
};

export const LoginRequired = (props: PropsWithChildren<{}>) => {
  const authContext = React.useContext(AuthContext);
  const location = useLocation();

  if (authContext.auth === false) {
    const urlParams = new URLSearchParams(location.search);
    const org = urlParams.get('org');
    if (org) {
      urlParams.delete('org');
    }

    const loginPath = org ? `/login/${org}` : `/login`;

    let next = location.pathname;
    if (urlParams.toString() !== '') {
      next = `${next}?${urlParams.toString()}`;
    }

    let redirectUrl;
    if (next === '/') {
      redirectUrl = `${loginPath}`;
    } else {
      redirectUrl = `${loginPath}?next=${encodeURIComponent(next)}`;
    }
    return <Navigate to={redirectUrl} />;
  }

  return <>{props.children}</>;
};
