import styled from 'styled-components';
import { PADecision, PASubmissionMode, PriorAuthResponse, url } from '../../api';
import { PDFViewer } from '../PDFViewer';
import {
  Sidebar,
  SidebarHeader,
  SidebarRow,
  SidebarRowData,
  SidebarRowLabel,
  SidebarSection,
} from '../sidebar/Sidebar';
import { DrugPanel, PatientPanel, PrescriberPanel } from '../sidebar/SidebarPanels';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0rem;
  height: 100%;
`;

const Content = styled.div`
  flex: 1;
`;

// Ensures that every option for a type as a key in the mapping
function createMapping<K extends string>(
  mapping: Record<K, string> & Record<Exclude<K, keyof Record<K, string>>, never>,
): Record<K, string> {
  return mapping;
}

const PADecisionDisplayValues = createMapping<PADecision>({
  PENDING: 'Pending',
  APPROVAL: 'Approved',
  ALREADY_APPROVED: 'Approved',
  DENIAL: 'Denied',
  PARTIAL_APPROVAL: 'Partially Approved',
  NOT_REQUIRED: 'Not Required',
});

const PASubmissionModeDisplayValues = createMapping<PASubmissionMode>({
  CMM: 'CoverMyMeds',
  FAX: 'Fax',
  PHONE: 'Phone',
  OTHER_TOOL: 'Unknown',
});

const PriorAuthView: React.FC<PriorAuthResponse> = (props) => {
  return (
    <Container>
      <Content>
        <PDFViewer pdfUrl={url(`/prior-auths/${props.pa.id}/document`)} />
      </Content>
      <Sidebar>
        <SidebarSection>
          <SidebarHeader>Prior Authorization</SidebarHeader>
          <SidebarRow>
            <SidebarRowLabel>Submitted On</SidebarRowLabel>
            <SidebarRowData>{new Date(props.pa.submitted_on_date).toLocaleDateString()}</SidebarRowData>
          </SidebarRow>
          <SidebarRow>
            <SidebarRowLabel>Decision</SidebarRowLabel>
            <SidebarRowData>{PADecisionDisplayValues[props.pa.decision]}</SidebarRowData>
          </SidebarRow>
          <SidebarRow>
            <SidebarRowLabel>Submission Mode</SidebarRowLabel>
            <SidebarRowData>{PASubmissionModeDisplayValues[props.pa.submission_mode]}</SidebarRowData>
          </SidebarRow>
          <SidebarRow>
            <SidebarRowLabel>Approved Until</SidebarRowLabel>
            <SidebarRowData>{new Date(props.pa.approval_until).toLocaleDateString()}</SidebarRowData>
          </SidebarRow>
        </SidebarSection>
        <DrugPanel drug={props.drug} />
        <PatientPanel patient={props.patient} />
        <PrescriberPanel prescriber={props.prescriber} />
      </Sidebar>
    </Container>
  );
};

export default PriorAuthView;
