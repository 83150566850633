import styled from 'styled-components';
import { SearchField } from './Input';
import { Button } from './Button';

export const TableContainer = styled.div`
  font-size: 0.9rem;
  color: var(--dark-gray);
  overflow-y: scroll;
`;

export const FilterBar = styled.div`
  display: flex;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid var(--border-gray);
  gap: 1rem;
`;

export const Table = styled.table`
  width: 100%;
  padding: 0 1.25rem;
  border-collapse: collapse;
  font-size: 1rem;
`;

export const TableHeader = styled.th`
  padding: 0.625rem;
  border: none;
  text-align: left;
  font-weight: 590;
  border-bottom: 1px solid var(--border-gray);
`;

export const TableCell = styled.td`
  padding: 0.625rem;
  border: none;
  color: black;
  font-weight: 400;
`;

export const SearchInput = styled(SearchField)`
  margin-left: auto;
  width: 30rem;
`;

export const ClickableRow = styled.tr<{ $selected: boolean; $new?: boolean }>`
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #c0c0c0;
  }
  ${({ $selected }) => ($selected ? 'background-color: #c0c0c0;' : '')}
  border-bottom: 1px solid var(--border-gray);

  // child TableCell
  & > td {
    ${({ $new }) => ($new ? 'font-weight: bold;' : '')}
  }

  &:last-of-type {
    border-bottom: none;
  }
`;

export const FilterGroup = styled.div`
  display: flex;
  padding: 0.3rem;
  background-color: var(--surface);
  gap: 0.5rem;
  border-radius: 8px;
`;

export const FilterLabel = styled.button<{ $active: boolean }>`
  color: var(${({ $active }) => ($active ? '--black' : '--dark-gray')});
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 590;
  line-height: 1rem;
  padding: 0.5rem 0.75rem;
  ${({ $active }) => ($active ? 'background-color: var(--white);' : 'background: none;')}
  ${({ $active }) => ($active ? 'box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.16);' : '')}
  border-radius: 5px;
  border: none;
  cursor: pointer;
`;

export const ActionButton = styled(Button)`
  padding: 0.5rem 0.75rem;
  margin: 0.3rem;
`;
