import { createContext } from 'react';
import { AppealReviewResponse } from '../../api';
import { UseQueryResult } from '@tanstack/react-query';

interface AppealReviewContext {
  appealReviewId: string;
  appealReviewQuery: UseQueryResult<AppealReviewResponse>;
  refetchAppealReview: () => Promise<void>;
  onAppealReviewUpdate: () => Promise<void>;
  updating: boolean;
  setUpdating: (value: boolean) => void;
}

export const AppealReviewContext = createContext<AppealReviewContext>({} as AppealReviewContext);
