import { Address, Drug, Patient, Prescriber } from '../../api';
import { SidebarRow, SidebarRowData, SidebarRowLabel, SidebarSection } from './Sidebar';
import React from 'react';
import { AttachedFile } from '../tasks/Files';

export const PatientPanel: React.FC<{ patient: Patient }> = ({ patient }) => {
  return (
    <SidebarSection>
      <SidebarRow>
        <SidebarRowLabel>Patient</SidebarRowLabel>
        <SidebarRowData>
          {patient.first_name} {patient.last_name}
        </SidebarRowData>
      </SidebarRow>
      <SidebarRow>
        <SidebarRowLabel>Date of birth</SidebarRowLabel>
        <SidebarRowData>{patient.date_of_birth}</SidebarRowData>
      </SidebarRow>
      <SidebarRow>
        <SidebarRowLabel>Phone</SidebarRowLabel>
        <SidebarRowData>{formatPhoneNumber(patient.phone_number)}</SidebarRowData>
      </SidebarRow>
      <AddressRow address={patient.address} />
    </SidebarSection>
  );
};

export const PrescriberPanel: React.FC<{ prescriber: Prescriber }> = ({ prescriber: prescriber }) => {
  // TODO: update this to include prescriber phone, fax, and address
  return (
    <SidebarSection>
      <SidebarRow>
        <SidebarRowLabel>Prescriber</SidebarRowLabel>
        <SidebarRowData>
          {prescriber.first_name} {prescriber.last_name}
        </SidebarRowData>
      </SidebarRow>
      <SidebarRow>
        <SidebarRowLabel>NPI</SidebarRowLabel>
        <SidebarRowData>{prescriber.npi}</SidebarRowData>
      </SidebarRow>
    </SidebarSection>
  );
};

export const DrugPanel: React.FC<{ drug: Drug }> = ({ drug }) => {
  return (
    <SidebarSection>
      <SidebarRow>
        <SidebarRowLabel>Drug</SidebarRowLabel>
        <SidebarRowData>{drug.name}</SidebarRowData>
      </SidebarRow>
      <SidebarRow>
        <SidebarRowLabel>Date Prescribed</SidebarRowLabel>
        <SidebarRowData>{new Date(drug.prescription_recieved_at).toLocaleDateString()}</SidebarRowData>
      </SidebarRow>
      <SidebarRow>
        <SidebarRowLabel>NDC</SidebarRowLabel>
        <SidebarRowData>{drug.ndc}</SidebarRowData>
      </SidebarRow>
      <SidebarRow>
        <SidebarRowLabel>Quantity</SidebarRowLabel>
        <SidebarRowData>{drug.quantity}</SidebarRowData>
      </SidebarRow>
      <SidebarRow>
        <SidebarRowLabel>Sig</SidebarRowLabel>
        <SidebarRowData>{drug.sig}</SidebarRowData>
      </SidebarRow>
      <SidebarRow>
        <SidebarRowLabel>Refills</SidebarRowLabel>
        <SidebarRowData>{drug.refills}</SidebarRowData>
      </SidebarRow>
    </SidebarSection>
  );
};

export const FilesPanel: React.FC<{ files: string[] }> = ({ files }) => {
  return (
    <SidebarSection>
      {files.map((fileId) => (
        <SidebarRow key={fileId}>
          <AttachedFile fileId={fileId} />
        </SidebarRow>
      ))}
    </SidebarSection>
  );
};

const AddressRow: React.FC<{ address: Address }> = ({ address }) => {
  return (
    <SidebarRow>
      <SidebarRowLabel>Address</SidebarRowLabel>
      <SidebarRowData>
        {[address.line1, address.line2, `${address.city}, ${address.state} ${address.postal_code}`]
          .filter(Boolean)
          .map((s, i) => (
            <React.Fragment key={i}>
              {s}
              <br />
            </React.Fragment>
          ))}
      </SidebarRowData>
    </SidebarRow>
  );
};

const formatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) {
    return '-';
  }

  // Use a regular expression to capture the different parts of the phone number
  const regex = /^(\d{3})(\d{3})(\d{4})$/;

  // Replace the matched pattern with the desired format
  const formattedNumber = phoneNumber.replace(regex, '($1) $2-$3');

  return formattedNumber;
};
