import styled from 'styled-components';

export const Button = styled.button`
  height: 44px;
  padding: 0.8rem 1rem;
  border-radius: 0.8rem;
  cursor: pointer;
  font-weight: 600;
  background: var(--purple);
  color: var(--white);
  border: none;

  &[disabled] {
    background: var(--border-gray);
    cursor: not-allowed;
  }
}
`;
