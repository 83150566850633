import { useState, useEffect } from 'react';
import { client } from '../api';

export const PDFViewer: React.FC<{ pdfUrl: string }> = ({ pdfUrl }) => {
  const [pdfContent, setPdfContent] = useState<string | null>(null);
  const [loadError, setLoadError] = useState(false);

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await client.get(pdfUrl, { responseType: 'blob' });
        const url = URL.createObjectURL(response.data);
        setPdfContent(url);
      } catch (error) {
        setLoadError(true);
      }
    };

    fetchPdf();
  }, [pdfUrl]);

  if (loadError) {
    return <div>We're having trouble downloading the prior auth document. Please try again later.</div>;
  }

  if (!pdfContent) {
    // TODO: use loading spinner
    return <div>Loading prior auth document...</div>;
  }

  return <iframe src={pdfContent} width="100%" height="100%" style={{ border: 'none' }} />;
};