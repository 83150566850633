import React from 'react';
import styled from 'styled-components';
import logo from '../assets/logo.svg';
import { AuthContext } from '../auth';
import { useStytchB2BClient } from '@stytch/react/b2b';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { NavLink, useNavigate } from 'react-router-dom';
import { client } from '../api';
import { PopoverMenu, PopoverMenuButton, PopoverMenuSection, PopoverMenuHeading, PopoverMenuItem, PopoverMenuItems } from './PopoverMenu';
import avatar from '../assets/avatar.svg';
import mail from '../assets/mail.svg';
import mailInverted from '../assets/mailInverted.svg';
import pieChart from '../assets/pieChart.svg';
import pieChartInverted from '../assets/pieChartInverted.svg';
import tasks from '../assets/tasks.svg';
import tasksInverted from '../assets/tasksInverted.svg';
// TODO: uncomment when this feature is ready to launch
// import placeholder from '../assets/placeholder.svg';
// import placeholderInverted from '../assets/placeholderInverted.svg';

const buttonStyles = `
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: var(--black);
  background-color: transparent;
  border-radius: 0.625rem;
  border: none;

  transition: background 0.2s ease-out, box-shadow 0.2s ease-out;

  &:focus-visible {
    box-shadow: 0px 0px 0px 1px var(--white), 0px 0px 0px 3px var(--purple);
    // for Windows High Contrast Mode, which removes box shadows but makes all borders
    // 100% opacity black
    outline: 1px solid rgba(0,0,0,0);
  }
`;

const navButtonStyles = `
  ${buttonStyles}

  // TODO(design-system): use body medium typography styles
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: 0.009375rem;

  &:hover {
    background: var(--cream2);
  }
  &:active, &:focus-visible {
    background: var(--cream3);
  }
`;

const StyledNavLink = styled(NavLink)`
  ${navButtonStyles}

  text-decoration: none;
  display: block;
`;

const AccountButton = styled(PopoverMenuButton)`
  ${navButtonStyles}
  
  padding: 0.75rem;
`;

const SignOutButton = styled.button`
  ${buttonStyles}

  padding: 0.5rem 1rem;

  &:hover, &:active, &:focus-visible {
    background: #ECE9F0;
  }

  // TODO(design-system): use body small typography styles
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.15px;
`;

const NavSidebarContainer = styled.div`
  // TODO: move to core css file, apply to everything
  box-sizing: border-box;

  width: var(--nav-sidebar-width);
  position: fixed;
  height: 100vh;
  // z-index lifts the sidebar above the main page content
  z-index: 1;

  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
  background: var(--light-surface);
`;

const LogoImage = styled.img`
  // TODO: move to core css file, apply to everything
  box-sizing: border-box;
  height: 3rem;
  padding: 0.875rem 1rem;
`;

const NavItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const Tabs = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const NavItemContainer = styled.div<{ $isActive: boolean }>`
  color: var(--dark-purple);

  padding: 0.75rem;
  display: flex;
  gap: 0.75rem;
  border-radius: 0.625rem;
  ${({ $isActive }) => ($isActive ? 'background-color: var(--cream3)' : '')}
`;

const NavItemIcon = styled.div`
  display: flex;
  align-items: center;
  // adding a width stops the text from jumping around when the icon loads
  width: 1rem;
`;

type NavItemProps = {
  icon: React.ReactNode,
  // The version of the icon that appears when this item is active
  // If not provided, the regular icon will be used
  activeIcon?: React.ReactNode,
  text: string,
  isActive: boolean,
};

const NavItem = ({ icon, activeIcon, text, isActive }: NavItemProps) => {
  return (
    <NavItemContainer $isActive={isActive}>
      <NavItemIcon>{isActive && activeIcon ? activeIcon : icon}</NavItemIcon>
      <div>{text}</div>
    </NavItemContainer>
  );
};

const SignoutMenu = ({ email }: { email?: string }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const logout = useMutation({
    mutationFn: () => {
      return client.post('logout');
    },
    onSuccess: async () => {
      stytch.session.revoke();
      await queryClient.invalidateQueries({ queryKey: ['checkLogin'] });
      navigate('/login');
    },
  });

  const stytch = useStytchB2BClient();

  return (
    <PopoverMenu>
      <AccountButton>
        <img src={avatar} alt="" /> Account
      </AccountButton>

      <PopoverMenuItems>
        <PopoverMenuSection>
          {email && (
            <PopoverMenuHeading>
              {email}
            </PopoverMenuHeading>
          )}

          <PopoverMenuItem>
            <SignOutButton onClick={() => logout.mutate()}>Sign out</SignOutButton>
          </PopoverMenuItem>
        </PopoverMenuSection>
      </PopoverMenuItems>
    </PopoverMenu>
  );
};

export const NavSidebar = () => {
  const authContext = React.useContext(AuthContext);

  return (
    <NavSidebarContainer>
      {/* Use anchor tag to force a full page reload in case the auth status has changed */}
      <a href="/">
        <LogoImage src={logo} alt="Home" />
      </a>
      {authContext.auth && (
        <NavItems>
          <Tabs>
            {authContext.user?.tabs.includes('dashboard') && (
              <StyledNavLink to="/">
                {({ isActive }) => (
                  <NavItem
                    isActive={isActive}
                    icon={<img src={pieChart} alt="" />}
                    activeIcon={<img src={pieChartInverted} alt="" />}
                    text="Dashboard"
                  />
                )}
              </StyledNavLink>
            )}
            {authContext.user?.tabs.includes('tasks') && (
              <StyledNavLink to="/tasks">
                {({ isActive }) => (
                  <NavItem
                    isActive={isActive}
                    icon={<img src={tasks} alt="" />}
                    activeIcon={<img src={tasksInverted} alt="" />}
                    text="Tasks"
                  />
                )}
              </StyledNavLink>
            )}
            {authContext.user?.tabs.includes('requests') && (
              <StyledNavLink to="/requests">
                {({ isActive }) => (
                  <NavItem
                    isActive={isActive}
                    icon={<img src={mail} alt="" />}
                    activeIcon={<img src={mailInverted} alt="" />}
                    text="Requests"
                  />
                )}
              </StyledNavLink>
            )}
            {/* TODO: uncomment when this feature is ready to launch */}
            {/* {authContext.user?.tabs.includes('appeal_reviews') && (
              <StyledNavLink to="/appeal-reviews">
                {({ isActive }) => (
                  <NavItem
                    isActive={isActive}
                    icon={<img src={placeholder} alt="" />}
                    activeIcon={<img src={placeholderInverted} alt="" />}
                    text="Reviews"
                  />
                )}
              </StyledNavLink>
            )} */}
          </Tabs>

          {authContext.auth && <SignoutMenu email={authContext.user?.email} />}
        </NavItems>
      )}
    </NavSidebarContainer>
  );
};
