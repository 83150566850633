import { useMutation } from '@tanstack/react-query';
import { client, url } from '../../api';
import { PDFViewer } from '../PDFViewer';
import { Sidebar } from '../sidebar/Sidebar';
import React, { useContext, useState } from 'react';
import { DrugPanel, PatientPanel, PrescriberPanel } from '../sidebar/SidebarPanels';
import styled, { keyframes } from 'styled-components';
import { AppealReviewContext } from './contexts';
import { Button } from '../Button';
import { FlexRow } from '../Layout';
import moment from 'moment';
import { Typography } from 'design-system/src/components/Typography/Typography';

// This formats moment to use a single letter for the time unit
// TODO: move to a helper file
moment.locale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s',
    s: '1s',
    ss: '%ss',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1M',
    MM: '%dM',
    y: '1Y',
    yy: '%dY'
  }
})

// TODO: dedupe a bunch of styles in this file with TaskView.tsx

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0rem;
  height: 100%;
`;

const Content = styled.div`
  flex: 1;
  padding: 1rem;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0 1.5rem;
`;

const FormContainer = styled.div`
  margin: 1.5rem 0;
`;

// TODO: make this an actual form
const Form = styled.div<{ $hasError: boolean }>`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  gap: 1rem;
  align-self: stretch;
  border-radius: 0.75rem;
  border: 2px solid var(--border-gray);
  background: var(--white);
  position: relative;
  overflow: hidden;
  transition: border-color 0.2s ease-out;

  ${({ $hasError }) => $hasError && `border-color: var(--red);`}

  // TODO: set this around the textarea, not on the border itself
  // so it doesn't override the error state
  &:has(:focus-visible) {
    border-color: var(--purple);
  }

  textarea {
    border: none;
    outline: none;
    padding: 0;
    font-size: 1rem;
  }
`;

const FormOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5); /* Semi-transparent white background */
  backdrop-filter: blur(3px); /* Blur effect */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it sits above other content */
`;

// TODO: add accessibility override
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Loader = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: transparent;
  border-top: 3px solid rgba(0, 0, 0, 0.7);
  border-right: 3px solid transparent;
  animation: ${spin} 1s linear infinite;
`;

const AppealReviewResponseInput = styled.textarea`
  border-radius: 6px;
  border: 1px solid var(--border-gray);
  background: var(--white);
  display: flex;
  height: 5.5rem;
  padding: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  resize: none;
`;

const ButtonRow = styled(FlexRow)`
  justify-content: flex-end;
`;

const FormButtonContainer = styled.div`
  display: flex;
  gap: 0.75rem;
  justify-content: space-between;
`;

const ReviewButtonContainer = styled.div`
  display: flex;
  gap: 0.75rem;
`;

const SmallButton = styled(Button)`
  display: flex;
  height: 2rem;
  padding: 0px 0.75rem;
  align-items: center;
  border-radius: 0.625rem;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.15px;
`;

const ReviewButton = styled(SmallButton)`
  color: var(--black);
  background-color: transparent;
  gap: 0.25rem;
  border: 1px solid var(--border-gray);
  transition: background-color 0.2s ease-out;
`;

const AcceptButton = styled(ReviewButton) <{ $selected: boolean }>`
  ${({ $selected }) => $selected && `
    background-color: #d4f0e1;
    border-color: #d4f0e1;
    color: var(--dark-green);
  `}
`;

const RejectButton = styled(ReviewButton) <{ $selected: boolean }>`
  ${({ $selected }) => $selected && `
    background-color: #ffd0d1;
    border-color: #ffd0d1;
    color: var(--dark-red);
  `}
`;

const ErrorMessage = styled(Typography)`
  color: var(--red);
  margin-top: 0.5rem;
`;

const ResponsesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 1.5rem;
  border-top: 1px solid var(--border-gray);
`;

const Response = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const ResponseIcon = styled.div`
  font-family: Parafina;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background: var(--purple);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
`;

const ResponseHeader = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
  margin-bottom: 2px;
`;

const ExtraSmallText = styled(Typography)`
  font-size: 0.75rem !important;
`;

const AppealReviewView: React.FC = () => {
  const rejectAppealInputRef = React.useRef<HTMLTextAreaElement>(null);

  const [reviewingState, setReviewingState] = useState<'default' | 'accepting' | 'rejecting'>('default');
  const [rejectionFormError, setRejectionFormError] = useState<boolean>(false);

  const {
    appealReviewQuery,
    appealReviewId,
    onAppealReviewUpdate,
    updating,
    setUpdating
  } = useContext(AppealReviewContext);

  const acceptAppeal = useMutation({
    mutationFn: async () => {
      const res = await client.post(`appeal-reviews/${appealReviewId}/accept`);
      return res.data;
    },
    onMutate: () => {
      setUpdating(true);
    },
    onSuccess: () => {
      onAppealReviewUpdate();
    },
  });

  const rejectAppeal = useMutation({
    mutationFn: async () => {
      const body = {
        response: rejectAppealInputRef.current?.value,
      };
      const res = await client.post(`appeal-reviews/${appealReviewId}/reject`, body);
      return res.data;
    },
    onMutate: () => {
      setUpdating(true);
    },
    onSuccess: () => {
      onAppealReviewUpdate();
    },
  });

  let body;
  if (appealReviewQuery.isLoading) {
    body = 'Loading appealReview...';
  } else if (appealReviewQuery.error) {
    body = 'Error, please try again';
  } else if (appealReviewQuery.data) {
    const appealReviewInfo = appealReviewQuery.data.appeal_review;

    // TODO: create this information programmatically, possibly on the backend before sending the data up
    const title = 'Lorem ipsum dolor sit amet ad minim.';
    const description = 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat enim ad minim veniam, quis nostrun laboris nisi.';

    body = (
      <Container>
        <Content>
          <Header>
            <Typography style="title" as="h2">{title}</Typography>
            <Typography>{description}</Typography>
          </Header>

          {appealReviewInfo.status === 'unreviewed' && (
            <FormContainer>
              <Form $hasError={rejectionFormError}>
                {updating && (
                  <FormOverlay>
                    <Loader />
                  </FormOverlay>
                )}

                <FormButtonContainer>
                  <ReviewButtonContainer>
                    <AcceptButton $selected={reviewingState === 'accepting'} onClick={() => {
                      if (reviewingState === 'accepting' && !updating) {
                        setReviewingState('default');
                      } else {
                        setReviewingState('accepting');
                        setRejectionFormError(false);
                      }
                    }}>
                      {/* TODO: add icon when this button is selected */}
                      Accept
                    </AcceptButton>
                    <RejectButton $selected={reviewingState === 'rejecting'} onClick={() => {
                      if (reviewingState === 'rejecting' && !updating) {
                        setReviewingState('default');
                        setRejectionFormError(false);
                      } else {
                        setReviewingState('rejecting');
                      }
                    }}>
                      {/* TODO: add icon when this button is selected */}
                      Request edits
                    </RejectButton>
                  </ReviewButtonContainer>

                  {reviewingState === 'accepting' && (
                    <SmallButton onClick={() => {
                      acceptAppeal.mutate();
                    }}>
                      Submit
                    </SmallButton>
                  )}
                </FormButtonContainer>

                {reviewingState === 'rejecting' && <>
                  <AppealReviewResponseInput
                    ref={rejectAppealInputRef}
                    placeholder="Add a response..."
                    defaultValue=""
                  />
                  <ButtonRow>
                    <SmallButton
                      onClick={() => {
                        if (rejectAppealInputRef.current?.value.length!!) {
                          rejectAppeal.mutate();
                        } else {
                          setRejectionFormError(true);
                        }
                      }}
                      disabled={rejectAppeal.isPending}
                    >
                      Submit
                    </SmallButton>
                  </ButtonRow>
                </>}
              </Form>
              {/* TODO: get final copy from design */}
              {rejectionFormError && <ErrorMessage style="smallBody" font={500}>You must add a reason to reject this appeal</ErrorMessage>}
            </FormContainer>
          )}

          {appealReviewInfo.status !== 'unreviewed' && (
            <ResponsesContainer>
              <Response>
                {/* TODO: replace with edit icon */}
                {/* @ts-ignore we're going to replace this anyway */}
                <ResponseIcon>{appealReviewInfo.reviewed_by.charAt(0)}</ResponseIcon>
                <ResponseHeader>
                  <Typography style="smallBody" weight={500}>{appealReviewInfo.reviewed_by}</Typography>
                  <ExtraSmallText style="smallBody">{appealReviewInfo.status} appeal</ExtraSmallText>
                  <span aria-hidden>·</span>
                  <ExtraSmallText style="smallBody">{moment(appealReviewInfo.reviewed_at).fromNow()}</ExtraSmallText>
                </ResponseHeader>
              </Response>

              {appealReviewInfo.status === 'rejected' && (
                <Response>
                  {/* This check is just to satisfy typescript. If the appeal has been reviewed, there should be a reviewed_by property */}
                  {appealReviewInfo.reviewed_by && <ResponseIcon>{appealReviewInfo.reviewed_by.charAt(0)}</ResponseIcon>}

                  <div>
                    <ResponseHeader>
                      <Typography style="smallBody" weight={500}>{appealReviewInfo.reviewed_by}</Typography>
                      <span aria-hidden>·</span>
                      <ExtraSmallText style="smallBody">{moment(appealReviewInfo.reviewed_at).fromNow()}</ExtraSmallText>
                    </ResponseHeader>

                    <Typography style="smallBody">{appealReviewInfo.reject_justification}</Typography>
                  </div>
                </Response>
              )}
            </ResponsesContainer>
          )}

          <PDFViewer pdfUrl={url(`/appeal-reviews/${appealReviewId}/document`)} />

        </Content>
        <Sidebar>
          <DrugPanel drug={appealReviewQuery.data.drug} />
          <PatientPanel patient={appealReviewQuery.data.patient} />
          <PrescriberPanel prescriber={appealReviewQuery.data.prescriber} />
        </Sidebar>
      </Container >
    );
  }

  return body;
};

export default AppealReviewView;
