import styled from 'styled-components';
import { Select, MenuItem } from '@mui/material';

export const DropdownItem = MenuItem;

export const Dropdown = styled(Select)`
  margin-top: -0.75rem;

  // stops the empty legend from breaking the focus outline of the input field
  > fieldset > legend {
    width: 0px;
  }
`;
