import axios from 'axios';

export const apiHost = import.meta.env.VITE_API_HOST;

export const client = axios.create({
  baseURL: apiHost,
  withCredentials: true,
});

export const url = (path: string) => {
  return `${apiHost}${path}`;
};

export type TaskStatus = 'queued' | 'completed' | 'processing' | 'cancelled';

export interface TaskSummary {
  drug_name: string;
  drug_quantity: string;
  drug_sig: string;
  id: string;
  patient_name: string;
  patient_dob: string;
  prescriber_name: string;
  prescription_recieved_at: string;
  task_created_at: string;
  task_instructions: string;
  task_status: TaskStatus;
  task_result: any;
  task_type: string;
}

export interface TasksResponse {
  tasks: TaskSummary[];
}

export interface Task {
  id: string;
  extra_context: string;
  status: TaskStatus;
  created_at: string;
  result: TaskResult;
  files: string[];
  type: string;
}

export interface TaskResult {
  data: any;
  files: any[];
  submitted_at: string;
  submitter: string;
}

export interface Address {
  city: string;
  line1: string;
  line2: string;
  postal_code: string;
  state: string;
}
export interface Patient {
  id: string;
  address: Address;
  date_of_birth: string;
  first_name: string;
  last_name: string;
  phone_number: string;
}

export interface Prescriber {
  first_name: string;
  last_name: string;
  npi: string;
  phone_number: string;
  fax_number: string;
  address: Address;
}

export interface Drug {
  name: string;
  quantity: string;
  sig: string;
  ndc: string;
  refills: string;
  prescription_recieved_at: string;
}
export interface TaskResponse {
  task: Task;
  patient: Patient;
  prescriber: Prescriber;
  drug: Drug;
}

export interface FileInfoResponse {
  id: string;
  filename: string;
}

export type PADecision = 'PENDING' | 'APPROVAL' | 'ALREADY_APPROVED' | 'DENIAL' | 'PARTIAL_APPROVAL' | 'NOT_REQUIRED';

export type PASubmissionMode = 'CMM' | 'FAX' | 'PHONE' | 'OTHER_TOOL';

export interface PriorAuth {
  id: string;
  submitted_on_date: string;
  request_type: string;
  decision: PADecision;
  approval_until: string;
  submission_mode: PASubmissionMode;
}

export interface PriorAuthResponse {
  patient: Patient;
  prescriber: Prescriber;
  drug: Drug;
  pa: PriorAuth;
}

export type RequestType =
  "authorization_update" |
  "pap_bridge_update" |
  "pharmacy" |
  "pa_renewal" |
  "other";

export interface Request {
  id: string;
  category: string;
  text: string;
  first_name: string;
  last_name: string;
  dob: string;
  type: RequestType;
  response: string;
  status: 'completed' | 'queued';
}

export interface RequestResponse {
  request: Request;
}

export interface RequestSummary {
  id: string;
  text: string;
  first_name: string;
  last_name: string;
  dob: string;
  created_at: string;
  status: string;
}

export type AppealReviewStatus = 'unreviewed' | 'accepted' | 'rejected' | 'redrafted';

// A column in the appeal reviews table
export interface AppealReviewSummary {
  id: string;
  drug_name: string;
  patient_name: string;
  prescriber_name: string;
  prescription_recieved_at: string;
}

// All columns in the appeal reviews table
export interface AppealReviewsResponse {
  appeal_reviews: AppealReviewSummary[];
}

export interface AppealReview {
  id: string;
  status: AppealReviewStatus;
  reviewed_by?: string;
  reviewed_at?: string;
  reject_justification?: string;
}

// Info in the appeal review detail view
export interface AppealReviewResponse {
  appeal_review: AppealReview;
  patient: Patient;
  prescriber: Prescriber;
  drug: Drug;
}
