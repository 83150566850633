import { StytchB2B } from '@stytch/react/b2b';
import { StytchB2BUIConfig, StytchEventType } from '@stytch/vanilla-js';
import PageContainer from './PageContainer';
import styled from 'styled-components';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { client } from '../api';
import { AxiosError } from 'axios';
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import React, { useRef } from 'react';
import { AuthContext } from '../auth';

import { B2BProducts, AuthFlowType, B2BOAuthProviders } from '@stytch/vanilla-js';

const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const domain = import.meta.env.VITE_STYTCH_REDIRECT_DOMAIN;

const passwordRedirectURL = `${domain}/password-reset`;
const defaultRedirect = '/';

interface FormData {
  token: string;
}

interface LoginResponse {
  // Define the structure of the response data you expect
}

interface ErrorResponse {
  error?: string;
}

const useLogin = (postLoginRedirect: string = defaultRedirect) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const login = useMutation<LoginResponse, AxiosError<ErrorResponse>, FormData>({
    mutationFn: (data) => client.post<LoginResponse>('login', data).then((res) => res.data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['checkLogin'] });
      navigate(postLoginRedirect);
    },
  });

  return login;
};

export const Login = () => {
  const authContext = React.useContext(AuthContext);
  const { slug } = useParams();
  const [searchParams] = useSearchParams();

  const postLoginRedirect = useRef(searchParams.get('next') || undefined);

  const config = slug
    ? getConfig(AuthFlowType.Organization, postLoginRedirect.current)
    : getConfig(AuthFlowType.Discovery, postLoginRedirect.current);

  const login = useLogin(postLoginRedirect.current);
  const callbacks = {
    onEvent: (event: { type: StytchEventType; data: any }) => {
      if (
        event.type === StytchEventType.B2BDiscoveryIntermediateSessionExchange ||
        event.type === StytchEventType.B2BOAuthAuthenticate ||
        event.type === StytchEventType.B2BPasswordAuthenticate ||
        event.type === StytchEventType.B2BMagicLinkAuthenticate
      ) {
        login.mutate({ token: event.data.session_token });
      }
    },
    onError: (data: any) => {
      console.error(data);
    },
  };

  if (authContext.auth === true) {
    return <Navigate to={postLoginRedirect.current || defaultRedirect} />;
  }

  return (
    <PageContainer title="Login" backgroundColor="cream">
      <LoginContainer>
        <StytchB2B config={config} callbacks={callbacks} />
      </LoginContainer>
    </PageContainer>
  );
};

export const PasswordReset = () => {
  const login = useLogin();

  const callbacks = {
    onEvent: (event: { type: StytchEventType; data: any }) => {
      if (
        event.type === StytchEventType.B2BPasswordResetByEmail ||
        event.type === StytchEventType.B2BPasswordResetBySession
      ) {
        login.mutate({ token: event.data.session_token });
      }
    },
    onError: (data: any) => {
      console.error(data);
    },
  };
  return (
    <PageContainer title="Reset Password" backgroundColor="cream">
      <LoginContainer>
        <StytchB2B config={getConfig(AuthFlowType.PasswordReset)} callbacks={callbacks} />
      </LoginContainer>
    </PageContainer>
  );
};

const getConfig = (type: AuthFlowType, postLoginRedirect: string | undefined = undefined): StytchB2BUIConfig => {
  const loginRedirectURL = postLoginRedirect
    ? `${domain}/login?next=${encodeURIComponent(postLoginRedirect)}`
    : `${domain}/login`;

  switch (type) {
    case AuthFlowType.Organization:
      return {
        authFlowType: AuthFlowType.Organization,
        products: [B2BProducts.emailMagicLinks, B2BProducts.oauth, B2BProducts.passwords],
        emailMagicLinksOptions: {
          loginRedirectURL: loginRedirectURL,
        },
        oauthOptions: {
          providers: [B2BOAuthProviders.Google, B2BOAuthProviders.Microsoft],
          loginRedirectURL: loginRedirectURL,
        },
        passwordOptions: {
          loginRedirectURL: loginRedirectURL,
          resetPasswordRedirectURL: passwordRedirectURL,
          resetPasswordExpirationMinutes: 20,
        },
        sessionOptions: {
          sessionDurationMinutes: 60,
        },
      };
    case AuthFlowType.Discovery:
      return {
        authFlowType: AuthFlowType.Discovery,
        products: [B2BProducts.emailMagicLinks, B2BProducts.oauth],
        emailMagicLinksOptions: {
          discoveryRedirectURL: loginRedirectURL,
        },
        oauthOptions: {
          providers: [B2BOAuthProviders.Google, B2BOAuthProviders.Microsoft],
          discoveryRedirectURL: loginRedirectURL,
        },
        sessionOptions: {
          sessionDurationMinutes: 60,
        },
      };
    case AuthFlowType.PasswordReset:
      return {
        authFlowType: AuthFlowType.PasswordReset,
        products: [B2BProducts.passwords],
        sessionOptions: {
          sessionDurationMinutes: 60,
        },
      };
  }
};
