import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { client } from '../api';
import PageContainer from './PageContainer';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      ['explo-dashboard']: any;
    }
  }
}

const DashboardEmbed: React.FC = () => {
  const { data, isLoading, error } = useQuery<{ jwt: string }>({
    queryKey: ['explo-jwt'],
    queryFn: () => {
      return client.post(`explo-jwt`).then((res) => res.data);
    },
  });
  let body;
  if (isLoading) body = 'Loading dashboard...';
  if (error) body = 'Error, please try again';
  if (data) {
    body = (
      <explo-dashboard
        dash-jwt={data.jwt}
        updateUrlParams={true}
        isProduction={true}
        environment="production"
        refresh-minutes={10}
      ></explo-dashboard>
    );
  }

  return <PageContainer title="Dashboard">{body}</PageContainer>;
};

export default DashboardEmbed;
