import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createBrowserRouter, RouteObject, RouterProvider } from 'react-router-dom';
import DashboardEmbed from './components/DashboardEmbed';
import { LoginProvider, LoginRequired } from './auth';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Login, PasswordReset } from './components/StytchLogin';

import { StytchB2BProvider } from '@stytch/react/b2b';
import { StytchB2BUIClient } from '@stytch/vanilla-js/b2b';
import TasksTablePage from './pages/TasksTablePage';
import TaskOverlay from './components/tasks/TaskOverlay';
import TaskView from './components/tasks/TaskView';

import * as Sentry from '@sentry/react';
import PriorAuthPage from './pages/PriorAuthPage';
import RequestsTablePage from './pages/RequestsTablePage';
import NewRequestForm from './components/requests/NewRequestForm';
import RequestOverlay from './components/requests/RequestOverlay';
import AppealReviewsTablePage from './pages/AppealReviewsTablePage';
import AppealReviewOverlay from './components/appealReviews/AppealReviewOverlay';

import 'unfonts.css';

Sentry.init({
  dsn: import.meta.env.PROD && import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['partner.withtandem.com'],
    }),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const queryClient = new QueryClient();
queryClient.setDefaultOptions({
  queries: {
    refetchOnWindowFocus: false,
    refetchInterval: false,
  },
});

const stytch = new StytchB2BUIClient(import.meta.env.VITE_STYTCH_KEY);

const routes: RouteObject[] = [
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/login/:slug',
    element: <Login />,
  },
  {
    path: '/password-reset',
    element: <PasswordReset />,
  },
  {
    path: '/',
    element: (
      <LoginRequired>
        <DashboardEmbed />
      </LoginRequired>
    ),
  },
  {
    path: '/tasks',
    element: (
      <LoginRequired>
        <TasksTablePage />
      </LoginRequired>
    ),
    children: [
      {
        path: ':taskId',
        element: <TaskOverlay />,
      },
    ],
  },
  {
    path: '/prior-auths/:paId',
    element: (
      <LoginRequired>
        <PriorAuthPage />
      </LoginRequired>
    ),
  },
  {
    path: 'requests',
    element: (
      <LoginRequired>
        <RequestsTablePage />
      </LoginRequired>
    ),
    children: [
      {
        path: 'new',
        element: <NewRequestForm />,
      },
      {
        path: ':requestId',
        element: <RequestOverlay />,
      },
    ],
  },
  {
    path: '/appeal-reviews',
    element: (
      <LoginRequired>
        <AppealReviewsTablePage />
      </LoginRequired>
    ),
    children: [
      {
        path: ':appealReviewId',
        element: <AppealReviewOverlay />,
      },
    ],
  },
];

if (import.meta.env.DEV) {
  // Place holder for quick edit functionality with limited auth
  // Leaving in dev only so we can test how the component will work
  // when we're ready to launch.
  routes.push({
    path: '/q/tasks/:taskId',
    element: (
      <LoginRequired>
        <TaskView />
      </LoginRequired>
    ),
  });
}

const router = createBrowserRouter(routes);

function App() {
  return (
    <StytchB2BProvider stytch={stytch}>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <LoginProvider>
            <RouterProvider router={router} />
          </LoginProvider>
        </LocalizationProvider>
      </QueryClientProvider>
    </StytchB2BProvider>
  );
}

export default App;
