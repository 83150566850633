export const tasksQueryKey = () => ['tasks'];

export const taskQueryKey = (id: string) => ['task', id];

export const taskFileQueryKey = (id: string) => ['task', 'files', id];

export const appealReviewsQueryKey = () => ['appealReviews'];

export const appealReviewQueryKey = (id: string) => ['appealReview', id];

