import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Patient, client } from '../../api';

import { Controller, useForm } from 'react-hook-form';

import { useNavigate, useOutletContext } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../Button';
import { FormLabel } from '../FormLabel';
import { TextArea } from '../Input';
import { Content, Overlay, animationDurationMs } from '../Modal';
import Typeahead, { TypeaheadOption } from '../Typeahead';
import { Dropdown, DropdownItem } from '../Dropdown.tsx.tsx';
import { formatDateOfBirth } from '../../utils';
import { AxiosError } from 'axios';
import { RequestType } from '../../api';

type FormData = {
  text: string;
  patientId: string;
  prescriptionId: string;
  type: RequestType;
};

type RxData = {
  drug: string,
  drug_name: string,
  id: string,
  prescriber_first_name: string,
  prescriber_last_name: string,
  written_date: string,
};

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 3rem;
`;

const createPatientOption = (patient: Patient): TypeaheadOption => {
  const option: TypeaheadOption = {
    value: patient.id,
    description: `${patient.first_name} ${patient.last_name} — DOB ${formatDateOfBirth(patient.date_of_birth)}`,
  };
  return option;
};

const createRxOption = (rxData: RxData): {
  description: string;
  value: string;
} => {
  const { id, drug, prescriber_first_name, prescriber_last_name, written_date } = rxData;
  const date = moment(written_date).format('M/DD/YYYY');

  const option: {
    description: string;
    value: string;
  } = {
    value: id,
    description: `${drug}, ${prescriber_first_name} ${prescriber_last_name}, ${date}`,
  };

  return option;
};

const Form: React.FC<{ onSubmit: () => void }> = (props) => {
  const submit = useMutation<{ task_id: string }, AxiosError<{ error: string }>, FormData>({
    mutationFn: async (data) => {
      return client.post(`requests`, data).then((res) => res.data);
    },
    onSuccess: () => {
      props.onSubmit();
    },
  });
  const { handleSubmit, control } = useForm<FormData>({});
  const [isFetchingRxList, setIsFetchingRxList] = useState<boolean>(false);
  const [rxList, setRxList] = useState<{ value: string, description: string }[]>([]);
  const [patients, setPatients] = useState([]);
  const query = useQuery({
    queryKey: ['patients'],
    queryFn: () => {
      return client.get(`patients`).then((res) => res.data);
    },
  });

  useEffect(() => {
    if (!query.data || !query.data.patients) {
      return;
    }
    setPatients(query.data.patients.map((patient: Patient) => createPatientOption(patient)));
  }, [query.data]);

  const getRxList = useMutation<{ rxs: RxData[] }, AxiosError<{ error: string }>, { patientId: string }>({
    mutationFn: async (data) => {
      return client.get(`rx_list/${data.patientId}`).then((res) => res.data);
    },
    onSuccess: async (res_data) => {
      setRxList(res_data.rxs.map((rxData: RxData) => createRxOption(rxData)));
      setIsFetchingRxList(false);
    },
    onError: async (errorResponse) => {
      // TODO: handle error state
      console.log(errorResponse);
      setIsFetchingRxList(false);
    },
  });

  const handlePatientChange = (onChange: (patientId: string) => void, patientId: string) => {
    onChange(patientId);
    setIsFetchingRxList(true);
    getRxList.mutate({ patientId: patientId });
  }

  return (
    <FormContainer
      onSubmit={handleSubmit((data) => {
        submit.mutate(data);
      })}
    >
      <h1>New request</h1>
      <Controller
        name="patientId"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <Typeahead
            options={patients}
            label={'Patient'}
            onChange={(patientId: string) => { handlePatientChange(field.onChange, patientId) }}
            value={field.value}
          />
        )}
      />
      <Controller
        name="type"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <>
            <FormLabel id="request-type-label">Request type</FormLabel>
            <Dropdown
              labelId="request-type-label"
              id="request-type"
              label="Request type"
              onChange={field.onChange}
              defaultValue=""
            >
              <DropdownItem value={"authorization_update"}>Authorization Update</DropdownItem>
              <DropdownItem value={"pap_bridge_update"}>PAP/Bridge Update</DropdownItem>
              <DropdownItem value={"pharmacy"}>Pharmacy</DropdownItem>
              <DropdownItem value={"pa_renewal"}>PA Renewal</DropdownItem>
              <DropdownItem value={"other"}>Other</DropdownItem>
            </Dropdown>
          </>
        )}
      />
      <Controller
        name="prescriptionId"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <>
            <FormLabel id="rx-label">Prescription</FormLabel>
            <Dropdown
              labelId="rx-label"
              id="rx"
              value={field.value}
              label="Prescription"
              onChange={field.onChange}
              disabled={!rxList.length || isFetchingRxList}
            >
              {rxList.map((rx) => (
                <DropdownItem key={rx.value} value={rx.value}>
                  {rx.description}
                </DropdownItem>
              ))}
            </Dropdown>
          </>
        )}
      />
      <Controller
        control={control}
        defaultValue=""
        name="text"
        rules={{ required: true }}
        render={({ field }) => <TextArea label="Request" placeholder="Request" rows={4} {...field} />}
      />
      <Button type="submit">Submit</Button>
      {submit.isError && <div>{submit.error?.response?.data.error}</div>}
    </FormContainer>
  );
};

export const NewRequestForm: React.FC = () => {
  const navigate = useNavigate();
  const [animateOut, setAnimateOut] = useState(false);

  const outletContext: any = useOutletContext();

  return (
    <Overlay
      onClick={() => {
        setAnimateOut(true);
        setTimeout(() => navigate('/requests'), animationDurationMs);
      }}
    >
      <Content onClick={(e) => e.stopPropagation()} $animateOut={animateOut}>
        <Form
          onSubmit={() => {
            setAnimateOut(true);
            setTimeout(() => navigate('/requests?status=queued'), animationDurationMs);
            outletContext.reload();
          }}
        />
      </Content>
    </Overlay>
  );
};

export default NewRequestForm;
