import React, { useCallback } from 'react';
import moment from 'moment';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  createColumnHelper,
  ColumnFiltersState,
  getFilteredRowModel,
} from '@tanstack/react-table';

import type { RequestSummary } from '../../api';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  ActionButton,
  ClickableRow,
  FilterBar,
  FilterGroup,
  FilterLabel,
  SearchInput,
  Table,
  TableCell,
  TableContainer,
  TableHeader,
} from '../Table';

const columnHelper = createColumnHelper<RequestSummary>();

interface TaskTableProps {
  data: RequestSummary[];
}

export const RequestTable: React.FC<TaskTableProps> = ({ data }) => {
  const navigate = useNavigate();
  const { requestId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([
    { id: 'status', value: searchParams.get('status') || 'completed' },
  ]);

  const columns = [
    columnHelper.accessor('created_at', {
      header: 'Request Date',
      cell: (info) => new Date(info.getValue()).toLocaleDateString(),
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('first_name', {
      header: 'First Name',
      enableGlobalFilter: true,
    }),
    columnHelper.accessor('last_name', {
      header: 'Last Name',
      enableGlobalFilter: true,
    }),
    columnHelper.accessor('dob', {
      header: 'Date of Birth',
      enableGlobalFilter: false,
      cell: (info) => moment(info.getValue()).format('MM/DD/YYYY'),
    }),
    columnHelper.accessor('text', {
      header: 'Request',
      enableGlobalFilter: false,
      cell: (info) => {
        const text = info.getValue();
        return text.length > 50 ? text.slice(0, 47) + '...' : text;
      },
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      enableGlobalFilter: false,
      cell: (info) => {
        const status = info.getValue();
        return status === 'queued' ? 'Open' : 'Complete';
      },
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnFiltersChange: setColumnFilters,
    enableGlobalFilter: true,
    globalFilterFn: 'includesString',
  });

  const setStatus = useCallback(
    (status: string) => {
      setSearchParams({ status });
      table.getColumn('status')!.setFilterValue(status);
    },
    [setSearchParams, table],
  );

  return (
    <TableContainer>
      <FilterBar>
        <FilterGroup>
          <FilterLabel
            $active={table.getColumn('status')!.getFilterValue() == 'queued'}
            onClick={() => setStatus('queued')}
          >
            Open Requests
          </FilterLabel>
          <FilterLabel
            $active={table.getColumn('status')!.getFilterValue() == 'completed'}
            onClick={() => setStatus('completed')}
          >
            Tandem Responses
          </FilterLabel>
        </FilterGroup>
        <SearchInput
          label="Search by name"
          hideLabel={true}
          onChange={(e) => table.setGlobalFilter((e.target as HTMLInputElement).value)}
        />
        <ActionButton onClick={() => navigate('new')}>New request</ActionButton>
      </FilterBar>
      <Table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHeader key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHeader>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <ClickableRow
                $selected={row.original.id === requestId}
                key={row.original.id}
                onClick={() => navigate(row.original.id)}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                ))}
              </ClickableRow>
            ))
          ) : (
            <tr>
              <TableCell colSpan={columns.length}>No results.</TableCell>
            </tr>
          )}
        </tbody>
      </Table>
    </TableContainer>
  );
};
