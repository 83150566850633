import styled from 'styled-components';

export const FlexRow = styled.div<{ $centerAlign?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  ${({ $centerAlign = false }) => $centerAlign && 'align-items: center'};
`;

export const FlexColumn = styled.div<{ $centerAlign?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ $centerAlign }) => ($centerAlign ? 'center' : 'flex-start')};
`;
