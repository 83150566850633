import React from 'react';
import { TextField, Autocomplete } from '@mui/material';

export type TypeaheadOption = {
  description: string;
  value: string;
};

type TypeaheadProps = {
  options: TypeaheadOption[];
  label: string;
  onChange: (value: string) => void;
  value: string;
};

const Typeahead: React.FC<TypeaheadProps> = ({ options, label, onChange, value }) => {
  const getOptionForValue = (value: string) => {
    return options.find((option) => option.value === value);
  };

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option: TypeaheadOption) => option.description}
      value={getOptionForValue(value)}
      onChange={(_, newValue) => {
        onChange(newValue ? newValue.value : '');
      }}
      renderInput={(params) => <TextField {...params} label={label} variant="outlined" fullWidth />}
    />
  );
};

export default Typeahead;
