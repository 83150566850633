import { useMutation, useQueryClient } from '@tanstack/react-query';
import { client } from '../../api';
import { Sidebar } from '../sidebar/Sidebar';
import React, { useContext } from 'react';
import { DrugPanel, FilesPanel, PatientPanel, PrescriberPanel } from '../sidebar/SidebarPanels';
import { FileUploader, UploadedFile } from './Files';
import styled, { keyframes } from 'styled-components';
import { taskQueryKey } from '../../queries';
import { TaskContext } from './contexts';
import { Button } from '../Button';
import { FlexRow } from '../Layout';
import moment from 'moment';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0rem;
  height: 100%;
`;

const Content = styled.div`
  flex: 1;
  padding: 16px;
`;

const FormContainer = styled.div`
  margin-top: 4rem;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--border-gray);
  background: var(--white);
  position: relative;
  overflow: hidden;

  &:has(:focus-visible) {
    border: 1px solid var(--purple);
  }

  textarea {
    border: none;
    outline: none;
    padding: 0;
    font-size: 1rem;
  }
`;

const FormOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5); /* Semi-transparent white background */
  backdrop-filter: blur(3px); /* Blur effect */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it sits above other content */
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Loader = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: transparent;
  border-top: 3px solid rgba(0, 0, 0, 0.7);
  border-right: 3px solid transparent;
  animation: ${spin} 1s linear infinite;
`;

const TaskResponseInput = styled.textarea`
  border-radius: 6px;
  border: 1px solid var(--border-gray);
  background: var(--white);
  display: flex;
  height: 88px;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  resize: none;
`;

const TaskInstructions = styled.p`
  font-family: Parafina;
  letter-spacing: 0.5px;
  font-size: 1.2rem;
  font-weight: 500;
`;

const ButtonRow = styled(FlexRow)`
  justify-content: flex-end;
`;

const TaskSubmitter = styled.div`
  display: inline-block;
`;

const TaskSubmittedAt = styled.div`
  display: inline-block;
  margin-left: 0.5rem;
  font-size: 0.75rem;
  color: var(--dark-gray);
`;

const TaskResponse = styled.div`
  padding: 0.5rem 0 1rem;
  font-weight: 400;
`;

const UploadedFileRow = styled(FlexRow)`
  gap: 0.5rem;
  justify-content: flex-start;
`;

const ResponseContainer = styled.div``;

const TaskView: React.FC = () => {
  // Rather than using this ref and a single input, we want to switch
  // to react json schema forms to allow for more dynamic task defintions
  // Until we do that, we are using a less robust but quicker to imoplement
  // solution.
  const inputRef = React.useRef<HTMLTextAreaElement>(null);

  const queryClient = useQueryClient();
  const { taskQuery, taskId, onTaskUpdate, updating, setUpdating } = useContext(TaskContext);

  const submit = useMutation({
    mutationFn: async () => {
      const body = {
        response: inputRef.current?.value,
      };
      const res = await client.post(`tasks/${taskId}/submit`, body);
      return res.data;
    },
    onMutate: () => {
      setUpdating(true);
    },
    onSuccess: () => {
      onTaskUpdate();
    },
  });

  let body;
  if (taskQuery.isLoading) {
    body = 'Loading task...';
  } else if (taskQuery.error) {
    body = 'Error, please try again';
  } else if (taskQuery.data) {
    let form;
    switch (taskQuery.data.task.status) {
      case 'queued':
        form = (
          <>
            {taskQuery.data.task.result?.data?.response ? (
              <ResponseContainer>
                {'submitter' in taskQuery.data.task.result && 'submitted_at' in taskQuery.data.task.result ? (
                  <>
                    <TaskSubmitter>{taskQuery.data.task.result['submitter']}</TaskSubmitter>
                    <TaskSubmittedAt>
                      {moment(taskQuery.data.task.result['submitted_at']).format('MMM Do YYYY, h:mm a')}
                    </TaskSubmittedAt>
                  </>
                ) : null}
                <TaskResponse>{taskQuery.data.task.result.data.response}</TaskResponse>
                {taskQuery.data.task.result.files?.map((fileId: string) => (
                  <UploadedFile key={fileId} fileId={fileId} />
                ))}
              </ResponseContainer>
            ) : null}
            <FormContainer>
              {updating && (
                <FormOverlay>
                  <Loader />
                </FormOverlay>
              )}
              <TaskResponseInput
                ref={inputRef}
                placeholder="Add a response..."
                defaultValue={taskQuery.data.task.result?.data?.response}
              />
              <UploadedFileRow>
                {taskQuery.data.task.result?.files?.map((fileId: string) => (
                  <UploadedFile
                    key={fileId}
                    fileId={fileId}
                    onFileDelete={async () => {
                      setUpdating(true);
                      await client.delete(`tasks/${taskId}/file/${fileId}`);
                      await queryClient.invalidateQueries({
                        queryKey: taskQueryKey(taskId),
                        exact: true,
                      });
                      setUpdating(false);
                    }}
                  />
                ))}
              </UploadedFileRow>
              <ButtonRow>
                <FileUploader />
                <Button
                  onClick={() => {
                    submit.mutate();
                  }}
                  disabled={submit.isPending}
                >
                  {taskQuery.data.task.result?.data?.response ? 'Update' : 'Submit'}
                </Button>
              </ButtonRow>
            </FormContainer>
          </>
        );
        break;
      case 'completed':
        form = (
          <FormContainer>
            <TaskResponseInput ref={inputRef} value={taskQuery.data.task.result.data.response} readOnly />
            {taskQuery.data.task.result.files?.map((fileId: string) => <UploadedFile key={fileId} fileId={fileId} />)}
          </FormContainer>
        );
        break;
    }
    body = (
      <Container>
        <Content>
          {taskQuery.data.task.type}
          <TaskInstructions>{taskQuery.data.task.extra_context}</TaskInstructions>
          {form}
        </Content>
        <Sidebar>
          <DrugPanel drug={taskQuery.data.drug} />
          <PatientPanel patient={taskQuery.data.patient} />
          <PrescriberPanel prescriber={taskQuery.data.prescriber} />
          <FilesPanel files={taskQuery.data.task.files || []} />
        </Sidebar>
      </Container>
    );
  }

  return body;
};

export default TaskView;
