import React from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Content, Overlay } from '../Slideout';
import { useQuery } from '@tanstack/react-query';
import { RequestResponse, client } from '../../api';
import styled from 'styled-components';

const RequestContent = styled.div`
  padding: 1rem 2rem;
`;
const FieldGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 30%;
  min-width: 300px;
  font-weight: 400;
`;
const FieldLabel = styled.div`
  color: var(--dark-gray);
  padding-right: 1rem;
`;
const FieldValue = styled.div``;
const RequestText = styled.div`
  font-weight: 400;
`;
const OpenResponseText = styled.div`
  color: var(--dark-gray);
  font-style: italic;
  font-weight: 400;
`;

const HeaderText = styled.h2``;
const SubHeaderText = styled.h3``;

const RequestOverlay: React.FC = () => {
  const params = useParams();
  const query = useQuery<RequestResponse>({
    queryKey: ['request', params.requestId],
    queryFn: () => {
      return client.get(`requests/${params.requestId}`).then((res) => res.data);
    },
  });

  let body;
  if (query.isLoading) {
    body = 'Loading request...';
  }
  if (query.error) {
    body = 'Error, please try again';
  }
  if (query.data) {
    body = (
      <RequestContent>
        <HeaderText>Inbound Request for Tandem</HeaderText>
        <SubHeaderText>Patient Info</SubHeaderText>
        <FieldGroup>
          <FieldLabel>First name</FieldLabel>
          <FieldValue>{query.data.request.first_name}</FieldValue>
        </FieldGroup>
        <FieldGroup>
          <FieldLabel>Last name</FieldLabel>
          <FieldValue>{query.data.request.last_name}</FieldValue>
        </FieldGroup>
        <FieldGroup>
          <FieldLabel>Date of birth</FieldLabel>
          <FieldValue>{moment(query.data.request.dob).format('MM/DD/YYYY')}</FieldValue>
        </FieldGroup>
        <SubHeaderText>Request to Tandem</SubHeaderText>
        <RequestText>{query.data.request.text}</RequestText>
        <SubHeaderText>Request type</SubHeaderText>
        <RequestText>{query.data.request.type}</RequestText>
        <SubHeaderText>Response from Tandem</SubHeaderText>
        {query.data.request.status == 'completed' ? (
          <RequestText>{query.data.request.response}</RequestText>
        ) : (
          <OpenResponseText>Tandem hasn't responded to this request yet. Please check back soon.</OpenResponseText>
        )}
      </RequestContent>
    );
  }

  return (
    <Overlay>
      <Content>
        {body}
      </Content>
    </Overlay>
  );
};

export default RequestOverlay;
