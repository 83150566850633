import styled from 'styled-components';

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  font-style: normal;
  line-height: 133.333%;
  width: 19.75rem;
  border-left: 1px solid var(--border-gray);
  padding: 0 1.5rem;
`;

export const SidebarSection = styled.div`
  border-bottom: 1px solid var(--border-gray);
  &:last-of-type {
    border-bottom: none;
  }
  padding: 1.5rem 0;
`;

export const SidebarHeader = styled.div`
  color: var(--black);
  font-weight: 590;
  margin-bottom: 0.5rem;
`;

export const SidebarRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.25rem;
  font-weight: 400;
`;

export const SidebarRowLabel = styled.div`
  color: var(--black);
  font-weight: 500;
  flex: 1;
`;

export const SidebarRowData = styled.div`
  color: var(--black);
  flex: 1;
`;
